<div>
    <div class="d-flex pt-3">
        <h3 *ngIf="currentPage!='kpi'" class="title" style="padding-left: 15px !important;" >Set your goals</h3>
        <h3 *ngIf="currentPage=='kpi'" class="title" style="padding-left: 15px !important;" >Quarterly KPIs</h3>
        
        
        <button *ngIf="currentPage=='kpi' && hasEnterpriseRole" (click)="saveData()" class="ml-auto btn mr-2 btn-primary newGoal">
            <i class="fa fa-save "></i> &nbsp; Save KPIs
        </button>       
        
        </div>
</div>
<div [ngSwitch]="currentPage">
    <div *ngSwitchCase="'kpi'" class="d-flex flex-column">
        <p class="hint pl-3 text-danger" *ngIf="!hasEnterpriseRole"><strong>View only:</strong> Your admin will input the KPIs for your company.</p>
        <div class="mt-2 d-flex flex-row align-items-center">
            <h4 class="col text-muted">
                <i class='fa fa-chevron-left fa-xs' style='cursor:pointer;' (click)="setCurrentKPI(-1)"></i>                
                {{currentKPI?.year}}
                <i class='fa fa-chevron-right me-2 fa-xs' style='cursor:pointer;' (click)="setCurrentKPI(1)"></i>
            </h4>
            <h4 class="col kpi-quarter" *ngFor="let item of currentKPI?.quarters; let indexQuarter= index; trackBy: trackByFn" [class.first-child]="indexQuarter==0" >
                <textarea name="kpiQuarter_{{indexQuarter}}" [(ngModel)]="currentKPI.quarters[indexQuarter]"  [ngModelOptions]="{standalone: true}" (blur)="autoSave()" [disabled]="!hasEnterpriseRole"
                [rows]="1" [placeholder]="!hasEnterpriseRole ? '': 'Quarter in here...'" class="w-100 h-100 border-0" ></textarea>            
            </h4>
        </div>
        <div class="card kpiCard">
            <div class="d-flex flex-row kpi-row align-items-center" [class.kpi-header]="first" *ngFor="let kpiCategory of currentKPI.categories; let indexCategory=index; let last=last; let first=first; trackBy: trackByFn">
                <h4 class="col kpi-category">
                    <textarea name="kpiCategory_{{indexCategory}}_{{kpiCategory}}"  [rows]="first? 1: 5" [disabled]="!hasEnterpriseRole"
                    [(ngModel)]="currentKPI.categories[indexCategory].label"  [ngModelOptions]="{standalone: true}" (ngModelChange)="setKPICategory($event)" (blur)="autoSave()" [placeholder]="!hasEnterpriseRole ? '': 'Add KPI...'" class="w-100 h-100 border-0 text-muted"></textarea>
                </h4>
                <h4 class="col kpi-column"  *ngFor="let item of currentKPI?.categories[indexCategory].data; let indexKPI=index; trackBy: trackByFn"> 
                    <textarea name="kpiText_{{indexCategory}}_{{kpiCategory}}" [(ngModel)]="currentKPI?.categories[indexCategory].data[indexKPI]"  [ngModelOptions]="{standalone: true}" (blur)="autoSave()" [disabled]="!hasEnterpriseRole"
                    [rows]="first? 1: 5" [placeholder]="!hasEnterpriseRole ? '': 'Text in here...'" class="w-100 h-100 border-0"></textarea>
                </h4>
            </div>
        </div>
    </div>
    <div *ngSwitchCase="'goal'" class="d-flex flex-column">        
        <div class="d-flex flex-row w-100">
            <div class="d-flex flex-column ms-3">
                <small class="text-fade mb-2" *ngIf="(selectedGoal.createdUserId != userId) || hasEnterpriseRole || (!!selectedGoal.goalTeams && hasTeamManagerRole)">View Only: contact the goal owner to edit this goal</small>
                <h2 class="goalTitle">         
                    {{selectedGoal.goalTitle}}
                </h2>
                <h5 *ngIf="selectedGoal.description" class="text-muted">{{selectedGoal.description}}</h5>
                <small  class="btn m-0 p-0 ps-2 mb-2 text-left text-muted" [routerLink]="['/goals']" >back</small>
            </div>
            <div class="ml-auto mr-0 mt-0 chart" style="margin-top: -65px !important;">               
                <apx-chart
                [colors]="chartColors[selectedGoal.goalLevelId]"
                [series]="[(selectedGoal?.status=='completed') ? 100 : (selectedGoal.progress||0)]"
                  [chart]="{height: 140, type: 'radialBar'}"
                  [plotOptions]="chartOptions.plotOptions"
                  [labels]="chartOptions.labels"
                  [dataLabels]="chartOptions.dataLabels"
                ></apx-chart>
               
              </div>
        </div>
        
        <div class="card goalCard mx-3 d-flex flex-column gap-2 pb-3">
            <div class="title my-3  ml-3 d-flex">Milestones
                <button *ngIf="currentPage=='goal' && (selectedGoal.createdUserId == userId) || hasEnterpriseRole || (!!selectedGoal.goalTeams && hasTeamManagerRole)" (click)="openMilestoneModal('create',{goalId:selectedGoal.id})" class="ml-auto mr-3 py-1 btn btn-outline-dark-primary">
                    <i class="fa fa-plus "></i> &nbsp; Add
                </button>
            </div>
        
            <div class="mx-3 gap-2 p-3 card milestoneCard d-flex flex-row align-items-center" *ngFor="let item of selectedGoal?.goalMilestones||[]; let i = index">
                <h4 >{{item.milestone}}</h4>
                <i class="ml-auto fa fa-check mb-1 {{(item?.status=='completed') ? 'text-success': 'text-fade'}}" 
                (touchend)="cancelClick($event); toggleMilestoneStatus(i)"
                (click)="cancelClick($event); toggleMilestoneStatus(i)"></i>
                  
                <i class="fa fa-trash px-2 mb-1 text-muted" style="font-size: 18px;" (click)="cancelClick($event); openDeleteMilestoneConfirmDialog(item)"
                *ngIf="(selectedGoal.createdUserId == userId) || hasEnterpriseRole || (!!selectedGoal.goalTeams && hasTeamManagerRole)"
                ></i>
                <i class="fa fa-edit text-muted" style="font-size: 18px;" (click)="cancelClick($event);openMilestoneModal('update', item)"
                *ngIf="(selectedGoal.createdUserId == userId) || hasEnterpriseRole || (!!selectedGoal.goalTeams && hasTeamManagerRole)"></i>
                
                <svg  viewBox="0 0 11 12" class="px-2 mb-1  {{item.targetDate? 'text-muted': 'text-fade'}}" style="width: 30px; height:30px" 
                [matTooltip]="tooltip('Due on','Set due date', item?.targetDate|date: dateFormat)" (click)="cancelClick($event); bsdue.toggle()">
                    <use xlink:href='assets/icons/hourglass.svg#img'></use>
                  </svg>
                
                
                <!--<i *ngIf="(selectedGoal.createdUserId == userId) || hasEnterpriseRole || (!!selectedGoal.goalTeams && hasTeamManagerRole)"
                class="fa fa-ellipsis-v pe-2 text-fade" [matMenuTriggerFor]="milestoneMenu"></i> -->

                 <input type="text" style="z-index: 500; width: 0px; opacity: 0; position: absolute; right: 150px;"
                placeholder="Due Date" name="milestoneTargetDate{{i}}" class="form-control input-style1 bg-grey p-0" 
                [(ngModel)]="item.targetDate"  (change)="updateMilestone(item)" [ngModelOptions]="{standalone: true}"
                #targetDate="ngModel" [minDate]="scheduledMinDate" 
                #bsdue="bsDatepicker" bsDatepicker placement="bottom" [bsValue]="item.targetDate"
                [bsConfig]="{showWeekNumbers:false, dateInputFormat: dateFormat, containerClass: 'theme-dark-blue', customTodayClass:'bs-current-date'}">


                <mat-menu #milestoneMenu="matMenu">
                    <span class="px-2 text-muted">MILESTONE SETTINGS</span>
                    <button mat-menu-item class="ps-2"
                        (click)="openMilestoneModal('update', item)">
                        <span> <i class="fa fa-edit pr-2"
                                style="color:#C0C0C0; width: 20px;"></i></span>
                        <span>Edit milestone</span>
                    </button>
                    <button mat-menu-item class="ps-2" (click)="toggleMilestoneStatus(item)">
                        <span> <i class="fa fa-check-circle pr-2" style="color:#C0C0C0;width: 20px;"></i></span>
                        <span>Mark {{item.status =='completed' ? 'incomplete': 'complete'}}</span>
                      </button>
                    <button mat-menu-item class="ps-2 text-danger"
                        (click)="openDeleteMilestoneConfirmDialog(item)">
                        <span> <i class="fa fa-trash pr-2" style="width: 20px;"></i></span>
                        <span>Delete milestone</span>
                    </button>
                </mat-menu>
            </div>
        </div>        
       
    </div>
    <div *ngSwitchDefault>
        <div class="d-flex flex-column" *ngIf="showgoals == true">
                <div *ngIf="enterpiseGoals.length > 0 || hasEnterpriseRole">
                    <div class="d-flex border-bottom ml-3 mb-3  goal-div">
                        <div class="title subtitle my-auto">Business goals</div>
                        <button *ngIf="currentPage=='goals' && hasEnterpriseRole" (click)="openGoalsModal($event, 'create', {}, 'enterprise')" class="ml-auto my-auto btn">
                            <svg viewBox="0 0 20 20" >
                                <use xlink:href='assets/icons/add.svg#img'></use>
                            </svg> Add
                        </button>
                        
                    </div>
                    <div class="ml-2 col-12">
                        <div class="d-flex flex-row flex-wrap gap-3">                            
                            <div class="stretch-card" *ngFor="let goal of enterpiseGoals ; let rowNumber = index">
                                <div class="card card-goals enterprise" style="border-radius: 15px;" [routerLink]="['/goals']" [queryParams]="{ currentPage: 'goal', id: goal.id }" queryParamsHandling="merge">
                                    <div class="d-flex ml-auto gap-2 align-items-end" style="min-height: 10px;margin-top: -5px;" (click)="cancelClick($event)">
                                        <i class="fa fa-ellipsis-h p-2 text-muted" *ngIf="hasEnterpriseRole" 
                                            [matMenuTriggerFor]="goal1Menu"></i>
                                    </div>
                                    <div class="card-body text-center d-flex flex-column" >
                                        <div class="chart">
                                            <apx-chart
                                            [colors]="chartColors[0]"
                                              [series]="[(goal?.status=='completed') ? 100 : (goal.progress||0)]"
                                              [chart]="{height: 100, type: 'radialBar'}"
                                              [plotOptions]="chartOptions.plotOptions"
                                              [labels]="chartOptions.labels"
                                              [dataLabels]="chartOptions.dataLabels"
                                            ></apx-chart>
                                          </div>
                                        <h2 class="card-title">
                                            {{goal.goalTitle || '-'}}
                                        </h2>
                                        <small class="card-subtitle">
                                            {{goal.goalMilestones?.length || 0 }} Milestones
                                        </small>
                                        <small class="text-muted" *ngIf="createdUser(goal)">
                                            Goal set by {{createdUser(goal)}}
                                        </small>
                                        <div class="mt-auto d-flex flex-row">
                                            <i *ngIf="hasEnterpriseRole" class="fa fa-check {{(goal?.status=='completed') ? 'text-success': 'text-fade'}}" 
                                                (touchend)="toggleGoalStatus('enterpiseGoals',rowNumber, $event)" (click)="toggleGoalStatus('enterpiseGoals',rowNumber, $event)" style='cursor: pointer'></i>
                                            
                                            <small class="ml-auto text-muted">
                                                {{goal.targetDate ? (goal.targetDate | date: dateFormat) : ''}}
                                            </small>
                                        </div>
                                        

                                    </div>
                                    <mat-menu #goal1Menu="matMenu">
                                        <span class="px-2 text-muted">GOAL SETTINGS</span>
                                        <button mat-menu-item class="ps-2"
                                            (click)="openGoalsModal($event, 'update', goal, 'enterprise')">
                                            <span> <i class="fa fa-edit pr-2"
                                                    style="color:#C0C0C0; width: 20px;"></i></span>
                                            <span>Edit goal</span>
                                        </button>
                                        <button mat-menu-item class="ps-2 text-danger"
                                            (click)="openDeleteConfirmDialog(goal)">
                                            <span> <i class="fa fa-trash pr-2" style="width: 20px;"></i></span>
                                            <span>Delete goal</span>
                                        </button>
                                    </mat-menu>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div  *ngIf="teamGoals.length > 0 || hasTeamManagerRole">
                    <div class="d-flex border-bottom ml-3 my-3  goal-div">
                        <div class="title subtitle my-auto ">Team goals</div>
                        <button *ngIf="currentPage=='goals' && hasTeamManagerRole" (click)="openGoalsModal($event, 'create', {}, 'team')" class="ml-auto my-auto btn">
                            <svg viewBox="0 0 20 20">
                                <use xlink:href='assets/icons/add.svg#img'></use>
                            </svg> Add
                        </button>
                        
                    </div>
                    <div class="ml-2 col-12">
                        <div class="d-flex flex-row flex-wrap gap-3">
                            <div class=" stretch-card" *ngFor="let goal of teamGoals ; let rowNumber = index">
                                <div class="card card-goals team" style="border-radius: 15px;" [routerLink]="['/goals']" [queryParams]="{currentPage: 'goal', id: goal.id }" queryParamsHandling="merge">
                                    <div class="d-flex ml-auto gap-2 align-items-end" style="min-height: 10px;margin-top: -5px;"  (click)="cancelClick($event)">
                                        <i class="fa fa-ellipsis-h p-2 text-muted" *ngIf="userId == goal.createdUserId || hasEnterpriseRole || hasTeamManagerRole"
                                            [matMenuTriggerFor]="goal2Menu" ></i>
                                    </div>
                                    <div class="card-body text-center d-flex flex-column">
                                        <div class="chart">
                                            <apx-chart
                                            [colors]="chartColors[1]"
                                              [series]="[(goal?.status=='completed') ? 100 : (goal.progress||0)]"
                                              [chart]="{height: 100, type: 'radialBar'}"
                                              [plotOptions]="chartOptions.plotOptions"
                                              [labels]="chartOptions.labels"
                                              [dataLabels]="chartOptions.dataLabels"
                                            ></apx-chart>
                                          </div>
                                          <h2 class="card-title">
                                            {{goal.goalTitle || '-'}}
                                        </h2>
                                        <small class="card-subtitle">
                                            {{goal.goalMilestones?.length || 0 }} Milestones
                                        </small>
                                        <small class="text-muted" *ngIf="createdUser(goal)">
                                            Goal set by {{createdUser(goal)}}
                                        </small>
                                        <div class="mt-auto d-flex flex-row">
                                            <i *ngIf="hasTeamManagerRole" class="fa fa-check {{(goal?.status=='completed') ? 'text-success': 'text-fade'}}" 
                                                (touchend)="toggleGoalStatus('teamGoals',rowNumber, $event)" (click)="toggleGoalStatus('teamGoals',rowNumber, $event)" style='cursor: pointer'></i>
                                            
                                            <small class="ml-auto text-muted">
                                                {{goal.targetDate ? (goal.targetDate | date: dateFormat) : ''}}
                                            </small>
                                        </div>

                                    </div>
                                    <mat-menu #goal2Menu="matMenu">
                                        <span class="px-2 text-muted">GOAL SETTINGS</span>
                                        <button mat-menu-item class="ps-2"
                                            (click)="openGoalsModal($event, 'update', goal, 'team')">
                                            <span> <i class="fa fa-edit pr-2"
                                                    style="color:#C0C0C0; width: 20px;"></i></span>
                                            <span>Edit goal</span>
                                        </button>
                                        <button mat-menu-item class="ps-2 text-danger"
                                            (click)="openDeleteConfirmDialog(goal)">
                                            <span> <i class="fa fa-trash pr-2" style="width: 20px;"></i></span>
                                            <span>Delete goal</span>
                                        </button>
                                    </mat-menu>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="d-flex border-bottom ml-3 my-3 goal-div">
                        <div class="title subtitle my-auto">Personal goals</div>
                        <button *ngIf="currentPage=='goals'" (click)="openGoalsModal($event, 'create', {}, 'individual')" class="ml-auto my-auto btn">
                            <svg viewBox="0 0 20 20" >
                                <use xlink:href='assets/icons/add.svg#img'></use>
                            </svg> Add
                        </button>
                        
                    </div>
                    <div class="ml-2 col-12">
                        <div class="d-flex flex-row flex-wrap gap-3">
                            <div class="stretch-card" *ngFor="let goal of individualGoals ; let rowNumber = index">
                                <div class="card card-goals individual" style="border-radius: 15px;" [routerLink]="['/goals']" [queryParams]="{currentPage: 'goal', id: goal.id }" queryParamsHandling="merge">
                                    <div class="d-flex ml-auto gap-2 align-items-end"  style="min-height: 10px;margin-top: -5px;" (click)="cancelClick($event)">
                                       <i class="fa fa-ellipsis-h p-2 text-muted"  *ngIf="userId == goal.createdUserId || hasEnterpriseRole || hasTeamManagerRole"
                                            [matMenuTriggerFor]="goal3Menu"></i>
                                    </div>
                                    <div class="card-body text-center d-flex flex-column" >
                                        <div class="chart">
                                            <apx-chart
                                              [colors]="chartColors[2]"
                                              [series]="[(goal?.status=='completed') ? 100 : (goal.progress||0)]"
                                              [chart]="{height: 100, type: 'radialBar'}"
                                              [plotOptions]="chartOptions.plotOptions"
                                              [labels]="chartOptions.labels"
                                              [dataLabels]="chartOptions.dataLabels"
                                            ></apx-chart>
                                          </div>
                                          <h2 class="card-title">
                                            {{goal.goalTitle || '-'}}
                                        </h2>
                                        <small class="card-subtitle">
                                            {{goal.goalMilestones?.length || 0 }} Milestones
                                        </small>
                                        <small class="text-muted" *ngIf="createdUser(goal)">
                                            Goal set by {{createdUser(goal)}}
                                        </small>
                                        <div class="mt-auto d-flex flex-row">
                                            <i class="fa fa-check {{(goal?.status=='completed') ? 'text-success': 'text-fade'}}" 
                                                (touchend)="toggleGoalStatus('individualGoals',rowNumber, $event)" (click)="toggleGoalStatus('individualGoals',rowNumber, $event)" style='cursor: pointer'></i>
                                            
                                            <small class="ml-auto text-muted">
                                                {{goal.targetDate ? (goal.targetDate | date: dateFormat) : ''}}
                                            </small>
                                        </div>

                                    </div>

                                    <mat-menu #goal3Menu="matMenu">
                                        <span class="px-2 text-muted">GOAL SETTINGS</span>
                                        <button mat-menu-item class="ps-2"
                                            (click)="openGoalsModal($event, 'update', goal, 'individual')">
                                            <span> <i class="fa fa-edit pr-2"
                                                    style="color:#C0C0C0; width: 20px;"></i></span>
                                            <span>Edit goal</span>
                                        </button>
                                        <button mat-menu-item class="ps-2 text-danger"
                                            (click)="openDeleteConfirmDialog(goal)">
                                            <span> <i class="fa fa-trash pr-2" style="width: 20px;"></i></span>
                                            <span>Delete goal</span>
                                        </button>
                                    </mat-menu>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</div>