import {AppDataServices} from '@store/entity/entity-services';
import {Component, EventEmitter, HostListener, OnDestroy} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {GoalsCreateComponent} from './goals-create/goals-create.component';
import {Constants} from '@common/global/app.global.constants';
import {GlobalService} from '@common/global/app.global.service';
import {DatePipe} from '@angular/common';
import * as _ from 'lodash';
import {combineLatest, from, Observable, of} from 'rxjs';
import {catchError, filter, map, switchMap} from 'rxjs/operators';
import {MilestoneCreateComponent} from './milestone-create/milestone-create.component';
import {ApexChart, ApexNonAxisChartSeries, ApexPlotOptions} from 'ng-apexcharts';
import {ActivatedRoute} from '@angular/router';
import {Goal} from '@models/goal';
import {Category} from '@models/category';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: string[];
  plotOptions: ApexPlotOptions;
  colors: any;
  dataLabels: any;
};

@Component({
  selector: 'app-goals',
  templateUrl: './goals.component.html',
  styleUrls: ['./goals.component.scss']
})

export class GoalsComponent implements OnDestroy {
  filterBy = 'goalTitle';
  filterBySort = 'goalTitle,asc';
  currentPage = 'goals';  // goals / goal / kpi
  modalRef: BsModalRef;
  allGoals: Goal[] = [];

  enterpiseGoals: Goal[] = [];
  teamGoals: Goal[] = [];
  individualGoals: Goal[] = [];

  milestonesData:any[] = [];
  newKpi: any = {year: new Date().getFullYear() ,
    quarters:['Q1','Q2','Q3','Q4'],
    categories:[
      { key:0, 
        label:'No of sales',
        data:[10,20,30,40]
      },
      { key:1, 
        label:'Product',
        data:['','','','']
      },
      { key:2, 
        label:'Finance',
        data:['','','','']
      },
      { key:3, 
        label:'Sales',
        data:['','','','']
      },
      { key:4, 
        label:'Marketing',
        data:['','','','']
      }
    ]};
  
  newKpiCategory:any = { key: 0,
    label:'',
    data:['','','','']
  };
  kpiList:any[] = [];
  orgKPIList:any[];
  currentKPI: any = {};
  currentKPIIndex:number;
  oldKPI = null; //used to get oldKPI

  modalType = 'create';

  allCatogeries: Category[] = [];
  miscellaneousCatogeryID: number = 0;

  displayStyle = 'grid';
  pageSize = 10;
  //currentPage = 1;
  direction: number;
  isDesc = false;
  column = 'goalTitle';
  searchBy_duplicate ='';
  sortDirection = 'asc';
	page: number;
  totalItems = 0;
  public eventT: EventEmitter<any> = new EventEmitter();
  /*name of the excel-file which will be downloaded. */
  fileName= 'ExcelSheet.xlsx';
  dataLength: number;
  taData: any[] = [];
  finalExportData: any[] = [];
  targetMinDate = new Date();
  userId:number;
  searchText = '';
  filter_by_level = false;
  goal_level_selection = ""
  filter_goal_level = 1;
  showgoals = true;
  selectedGoal: any;
  roles = Constants.roles;
  currentUserRoleId = 0;
  scheduledMinDate = new Date();
  goalLevels:any[] = [];
  chartOptions: Partial<ChartOptions>;
  chartColors = {
    0:["#F3A490"], //enterprise
    1:['#DC6ACF'], // team
    2:['#F5B858'] // individual
  }

  //userIds=[];
  hasTeamManagerRole = false;
  hasEnterpriseRole = false;
  
  notSaved = false;
  autoSaved = false;
  userCollection:any = {};

  constructor(private modalService: BsModalService, private appDataServices: AppDataServices,
    private route:ActivatedRoute, private globals:GlobalService, private datepipe: DatePipe ) {
    
    this.userId = this.globals.currentUserId;
    this.currentUserRoleId = this.globals.currentUserRoleId;
    this.hasTeamManagerRole = !!this.globals.getRole(this.roles.TEAM);
    this.hasEnterpriseRole = !!this.globals.getRole(this.roles.ENTERPRISE);

    this.getLists();
    this.initCharts();

  }

  @HostListener("window:beforeunload")
  @HostListener("window:blur")
  ngOnDestroy(): void {
    let notSaved = this.hasEnterpriseRole && this.currentPage=='kpi' && this.notSaved && this.currentKPI;
    if(notSaved)
      this.setKPI();
  }
  
  canDeactivate(): Observable<boolean> {
    let notSaved = this.hasEnterpriseRole && this.currentPage=='kpi' && this.notSaved && this.currentKPI;
    return notSaved ? this.confirmClose() : of(true); // automatic save
   }	

  confirmClose() {
    return this.globals.showConfirm(`Do you want to save your changes?`,true, true, 'Save Changes', 'Discard Changes')
    .pipe(
      switchMap(item=>{
        if(item)
          return this.setKPI();
        else {
          this.resetKPI();
        }        
        return of(true);
      })
    );
  }

  initCharts() {
    this.chartOptions = {
      series: [70],
      colors:['#729CA2'],
      chart: {
        height: 120,
        type: "radialBar"
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "35%"
          },
          dataLabels: {
            show: true,
            name: {
              show: false,
            },
            value: {
              color: "#008080",
              fontSize: "14px",
              offsetY: 5,
              show: true
            }
          },
        },
      },
      
      labels: [""]
    };
  }


  trackByFn(item, id){
    return item
  }

  get dateFormat() {
    return this.globals.dateFormat.toLowerCase().replace(/m/g,'M');
  }

  getLists() {
		this.goalLevels = [];
		this.allCatogeries = [];
		this.globals.showLoading();
		this.appDataServices.loaded$.pipe(filter(loaded=>!!loaded),switchMap(()=>
			combineLatest([
				this.appDataServices.categoryService.categories$,
				this.appDataServices.commonService.goalLevels$,
        this.appDataServices.goalService.goals$,
        this.appDataServices.goalService.kpi$,
        this.route.queryParams,
        this.appDataServices.userService.collection$        
			]))
        ).subscribe(([categories, goalLevels, goals, kpi, params, userCollection]) => {
      this.globals.hideLoading();
      let {currentPage, id} = params || {};
      if(currentPage!=this.currentPage && this.currentPage=='kpi') {
        this.canDeactivate().subscribe()
      } else {
        this.initKPI(kpi, userCollection);
      }
      
			this.goalLevels = goalLevels || [];
			this.allCatogeries = categories || [];
      this.miscellaneousCatogeryID = (this.allCatogeries.find(item=>item.name== "Miscellaneous")||{}).id || 0;
			
      this.allGoals = _.orderBy(goals||[], ['targetDate'],['asc']);
      
      if(this.goalLevels[0] && this.goalLevels[0].levelName == 'Enterprise'){
        //this.chartColors[this.goalLevels[0].id]=[...this.chartColors[0]];
        this.enterpiseGoals = _.orderBy(this.allGoals.filter(x=>x.goalLevelId == this.goalLevels[0].id),['targetDate'],['asc'])
      }
      if(this.goalLevels[1] && this.goalLevels[1].levelName == 'Team'){
        //this.chartColors[this.goalLevels[1].id]=[...this.chartColors[1]];
        this.teamGoals = _.orderBy(this.allGoals.filter(x=>x.goalLevelId == this.goalLevels[1].id),['targetDate'],['asc'])
      }
      if(this.goalLevels[2] && this.goalLevels[2].levelName == 'Individual'){
        //this.chartColors[this.goalLevels[2].id]=[...this.chartColors[2]];
        this.individualGoals = _.orderBy(this.allGoals.filter(x=>x.goalLevelId == this.goalLevels[2].id),['targetDate'],['asc'])
      }
      if(this.selectedGoal)
        this.selectedGoal = this.allGoals.find(item=>item.id==this.selectedGoal.id);

      this.showgoals = true;
      this.totalItems = this.allGoals.length;
      

      this.currentPage = currentPage || this.currentPage;
      if(id) {
        this.selectedGoal = this.allGoals.find(item=>item.id==params.id);
        this.currentPage = 'goal';
      } else if(this.currentPage == 'goal')
        this.currentPage = 'goals';
            }, (err) => {
              this.globals.hideLoading();
            }
        )
	}

  cancelClick(event: Event){
    event.preventDefault();
    event.stopPropagation();
  }

  initKPI(kpi, userCollection) {
    if (!this.notSaved && kpi && kpi.length > 0 && kpi[0].categories && kpi[0].quarters)
      this.kpiList = _.cloneDeep(kpi || []);

    this.userCollection = userCollection || {};
    this.currentKPI = (this.kpiList || []).find(item => item.year == this.newKpi.year) || {};
    if (!(this.currentKPI.categories || [{}])[0].label) {
      this.kpiList = [];
    }
    if (this.kpiList.length == 0 || !this.currentKPI.year) { // no current year
      this.kpiList.push(_.cloneDeep(this.newKpi));
    }
    this.currentKPI = (this.kpiList || []).find(item => item.year == this.newKpi.year) || {};

    if (this.currentKPIIndex != null) {
      this.currentKPI = this.kpiList[this.currentKPIIndex];
    }

    let labels = (this.currentKPI.categories || []).map(item => item.label.trim());
    if (!labels.includes('')) {
      this.setKPICategory(''); // add new row
    }

    if (!this.orgKPIList)
      this.orgKPIList = _.cloneDeep(this.kpiList || []);
  }
  saveData() {
    this.setKPI().subscribe();
  }

  resetKPI() {
    this.kpiList = _.cloneDeep(this.orgKPIList || []);
    this.currentKPI = (this.kpiList ||[]).find(item=>item.year==this.newKpi.year) || {};
    this.notSaved = false;      
  }

  autoSave() {    
    this.notSaved = true;
    this.autoSaved = true;
    this.saveData();
  }

  setKPI() {
    this.globals.showLoading();
    this.notSaved = false;
    return this.appDataServices.goalService.setKPI(this.kpiList)
    .pipe(
      map(()=>{
      this.orgKPIList = _.cloneDeep(this.kpiList || []);
      if(!this.autoSaved)
      this.globals.showSuccessMessage("KPI is Saved!");
      this.globals.hideLoading();
      this.autoSaved = false;
      return true;
    }),catchError(()=>{
      if(!this.autoSaved)
      this.globals.showErrorMessage("KPI is Not Saved!, please try again later");
      this.globals.hideLoading();
      this.autoSaved = false;
      return of(false);
    }));
  }

  createdUser(goal){
    return (this.userCollection.entities[+goal.createdUserId]||{}).name || null;
  }


  setKPICategory(newItem) {
    this.notSaved=true;
    if(newItem.trim()==='') {
      this.currentKPI.categories = this.currentKPI.categories.filter((item, index)=>index<5 || item.label.trim()!='');
    }
    let key = new Date().getTime();
    let newRow = {...this.newKpiCategory, key};
    let last = (this.currentKPI.categories||[])[this.currentKPI.categories.length-1];
    if(last.label!=''){
      this.currentKPI.categories.push(_.cloneDeep(newRow));
    }
  }

  

  setCurrentKPI(increment) {
    let index = this.kpiList.findIndex(item=>item.year==this.currentKPI.year+increment);    
    if(index<0){ // add new
      let newkpi = {...(_.cloneDeep(this.newKpi)), year: this.currentKPI.year+increment};
      this.kpiList.splice(index+increment, 0,newkpi);
    }
    if(index<0) index = 0;
    this.currentKPIIndex = index;
    this.currentKPI = this.kpiList[this.currentKPIIndex];
    
    let labels = (this.currentKPI.categories||[]).map(item=>item.label.trim())
    if(!labels.includes('')) {
      this.setKPICategory(''); // add new row
    }
  }

  toggleMilestoneStatus(index){
    let item:any = (this.selectedGoal.goalMilestones||[])[index];
    if(!item) return;
    item = {...item,
      status: item.status=='completed' ? 'new': 'completed',
      completionDate: item.status=='completed' ? null: new Date()
    }
    this.selectedGoal.goalMilestones[index] = item;
    this.globals.showLoading();
    this.appDataServices.milestoneService.update(item).subscribe(res=>{
      this.globals.hideLoading();
      this.appDataServices.goalService.load().subscribe();
    },()=>{
      this.globals.hideLoading(); 
    })
  }

  updateMilestone(item){
    this.globals.showLoading();
    this.appDataServices.milestoneService.update(item).subscribe(res=>{
      this.globals.hideLoading();
      this.appDataServices.goalService.load().subscribe();
    },()=>{
      this.globals.hideLoading(); 
    })
  }

  toggleGoalStatus(array:string , index, event){
    if(event){
      this.cancelClick(event);
    }
    let item:any = (this[array]||[])[index];
    if(!item) return;
    
    item = {...item,
      status: item.status=='completed' ? 'new': 'completed',
      goalTeams : (item.goalTeams||[]).map(team=>team.id||team),
      completionDate: item.status=='completed' ? null: new Date()
    }
    Object.assign(this[array][index], item);

    this.globals.showLoading();
    this.appDataServices.goalService.update(item).subscribe(res=>{
      this.globals.hideLoading();
      //this.appDataServices.goalService.load().subscribe();
    },()=>{
      this.globals.hideLoading(); 
    })
  }

  tooltip(label,emptyLabel, value){
    return `${value ? label: emptyLabel}`+ (value ? `: ${value}`:'');
  }

  openMilestoneModal(type,data) {
    const initialState = {
      modalType: type,
      modalData: {...data, createdUserId:this.globals.currentUserId},
      modalCategories:this.allCatogeries
    };
    this.modalRef = this.modalService.show(MilestoneCreateComponent, { 
      initialState,
      class: 'gray modal-lg',
      backdrop: 'static'
     });
  }

  openGoalsModal(event, type,data, goalType) {
    const initialState = {
      modalType: type,
      modalData: {...data, goalType},
      modalCategories:this.allCatogeries
    };
    this.modalRef = this.modalService.show(GoalsCreateComponent, { 
      initialState,
      class: 'gray modal-lg',
      backdrop: 'static'
     });
  }

  openDeleteMilestoneConfirmDialog(milestone) {
    this.globals.showConfirm('Are you sure you want to delete this milestone?', true, true, 'Delete', 'Cancel','confirm-danger' , 'confirm-success' )
    .subscribe((result) => {
      if (result) {
        this.globals.showLoading();
        this.appDataServices.milestoneService.delete(milestone).subscribe(
          () => {
            this.globals.hideLoading();
            this.globals.showSuccessMessage("Milestone deleted successfully!");
            this.appDataServices.goalService.load().subscribe();
          },()=>{
            this.globals.hideLoading();
          });
      }
    }, ()=>{
      this.globals.hideLoading();
    })
  }

  openDeleteConfirmDialog(goal) {
    this.globals.showConfirm('Are you sure you want to delete this goal?', true, true, 'Delete', 'Cancel','confirm-danger' , 'confirm-success' )
    .subscribe((result) => {
      if (result) {
        this.globals.showLoading();
        this.appDataServices.goalService.delete(goal).subscribe(
          () => {
            this.globals.hideLoading();
            this.globals.showSuccessMessage("Goal deleted successfully!");
            //this.appDataServices.goalService.load().subscribe();

          },()=>{
            this.globals.hideLoading();
          });
      }
    })
  }

}
